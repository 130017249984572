import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const format = (date, format) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return dayjs(`${date}`).tz(browserTimezone).format(format);
}
export default {
  format
}