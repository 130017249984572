import {
  GET_EVENT,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR
} from '../actions/eventActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  item: null
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT:
      return { ...state, isLoading: true };
    case GET_EVENT_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, item: action.item };
    case GET_EVENT_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default eventReducer;