import { combineReducers } from 'redux';
import archiveItemReducer from './archiveItemReducer';
import archiveItemsReducer from './archiveItemsReducer'
import sessionsReducer from './sessionsReducer';
import homeContentItemsReducer from './homeContentItemsReducer';
import speakersReducer from './speakersReducer';
import teamReducer from './teamReducer';
import eventReducer from './eventReducer';
import breakoutRoomsReducer from './breakoutRoomsReducer';
import breakoutRoomReducer from './breakoutRoomReducer';
import networkingReducer from './networkingReducer';

const rootReducer = combineReducers({
  archiveItem: archiveItemReducer,
  archiveItems: archiveItemsReducer,
  sessions: sessionsReducer,
  homeContentItems: homeContentItemsReducer,
  speakers: speakersReducer,
  team: teamReducer,
  event: eventReducer,
  breakoutRooms: breakoutRoomsReducer,
  breakoutRoom: breakoutRoomReducer,
  networking: networkingReducer
});

export default rootReducer;