import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Button } from '../components';
import APIService from '../helpers/apiService';
import { validString } from '../helpers/loginHelper';
import { saveAccessToken } from '../helpers/authHeader';

const formModes = {
  NOT_SET: 'not set',
  EXISTS: 'exists',
  DOES_NOT_EXIST: 'does not exist',
  TOO_MANY_REQUESTS: 'too many requests'
}

const LoginForm = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [passcode, setPasscode] = useState("");
  const [formMode, setFormMode] = useState(formModes.NOT_SET);

  const canSubmit = useMemo(() => (
    validString(firstName, 1, 50) && validString(lastName, 1, 50) && validString(email, 1, 255) && validString(passcode, 1, 16)
  ), [firstName, lastName, email, passcode]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { status, data } = await APIService.post('users/login', {
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      passcode: passcode.trim()
     });

    if (status === 200) {
      saveAccessToken(data);
      history.push('/');
      // setFormMode(formModes.EXISTS);
    } else if (status === 429) {
      setFormMode(formModes.TOO_MANY_REQUESTS);
    } else if (status === 400 || status === 401) {
      setFormMode(formModes.DOES_NOT_EXIST);
    }
  }

  let message
  switch(formMode) {
    case formModes.EXISTS:
      message = <div className="Login__Form__Message">Thank you!  We&rsquo;ve matched your email address and are sending you a unique link now.<br />Check your email, click on the link and you&rsquo;ll be automatically logged in.</div>;
      break;
    case formModes.DOES_NOT_EXIST:
      message = <div className="Login__Form__Message">Sorry, that email or passcode doesn&rsquo;t look right. Please check that you&rsquo;ve entered them correctly and try again.</div>;
      break;
    case formModes.TOO_MANY_REQUESTS:
      message = <div className="Login__Form__Message">Maximum login attempts exceeded, please try again in 5 minutes.</div>;
      break;
    default:
      message = null;
  }

  return (
    <div className={classNames({
      'Login__Form': true,
      'Login__Form--success': formMode === formModes.EXISTS,
      'Login__Form--error' : [formModes.DOES_NOT_EXIST, formModes.TOO_MANY_REQUESTS, formModes.INVALID_PASSCODE].indexOf(formMode) !== -1
    })}>
      <form onSubmit={onSubmit}>
        {message}
        <label>
          <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' name='email' placeholder='Enter your DNV email address *' />
        </label>
        <div className="Login__Form__Names">
          <label>
            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type='text' name='firstName' placeholder='Enter your first name *' />
          </label>
          <label>
            <input value={lastName} onChange={(e) => setLastName(e.target.value)} type='text' name='lastName' placeholder='Enter your last name *' />
          </label>
          <label>
            <input value={passcode} onChange={(e) => setPasscode(e.target.value)} type='text' name='passcode' placeholder='Enter the event passcode *' />
          </label>
        </div>
        <Button text='Login' classes='Button Button--green' enabled={canSubmit} type="submit" />
      </form>
    </div>
  )
}

export default LoginForm;




