import React, { useEffect, useState } from 'react';

const NetworkingCountdownTimer = ({ duration, started, onEnd }) => {
  const [timeLeftSeconds, setTimeLeftSeconds] = useState(duration);

  useEffect(() => {
    const countdown = () => {
      if ( started ) {
        setTimeLeftSeconds(prevTime => {
          return prevTime - 1;
        });
      }
    }

    const timeoutHandler = () => {
      if ( !started ) {
        onEnd();
      }
    }

    const tick = setInterval(countdown, 1000);
    const endSessionTimer = setTimeout(timeoutHandler, 15000); // end session if it doesn't start within 15 seconds

    return () => {
      clearInterval(tick);
      clearTimeout(endSessionTimer);
    }
  }, [started]);

  useEffect(() => {
    if ( timeLeftSeconds === 0 ) {
      onEnd();
    }
  }, [timeLeftSeconds]);

  // Handle somebody leaving mid-way through a session
  if (!started && timeLeftSeconds < duration) {
    onEnd();
  }

  const timeRemaining = {
    minutes: `${Math.floor(timeLeftSeconds / 60)}`.padStart(2, '0'),
    seconds: `${timeLeftSeconds % 60}`.padStart(2, '0'),
    finalTen: timeLeftSeconds <= 10
  }

  return (
    <div className={`Networking__session__timer ${!started ? 'Networking__session__timer--waiting' : ''} ${timeRemaining.finalTen ? 'Networking__session__timer--ending' : ''}`}>
      {started ? (
        <React.Fragment>
          Time remaining: <span>{timeRemaining.minutes}:{timeRemaining.seconds}</span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          Waiting for another user to join your networking session
        </React.Fragment>
      )}
    </div>
  );
}

export default NetworkingCountdownTimer;