import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppContext } from '../App';
import { fetchSpeakers } from '../redux/actions/speakersActions';
import userHelper from '../helpers/userHelper';
import dateHelper from '../helpers/dateHelper';

import { TeamMember, Chat } from '../components';

const LiveStream = () => {
  const { event } = useContext(AppContext);
  const dispatch = useDispatch();

  const { isLoading, hasErrors, speakers } = useSelector(
    ({ speakers }) => ({
      isLoading: speakers.isLoading,
      hasErrors: speakers.hasErrors,
      speakers: speakers.speakers,
    })
  );

  useEffect(() => {
    dispatch(fetchSpeakers());
  }, [dispatch]);

  return (
    <div className={`LiveStream ${event && !event.showChat ? "FullWidthVideo" : ""}`} >
      <div className="LiveStream__grid">
        <div className="LiveStream__title">
          <h1 className='HeaderTitle HeaderTitle--left HeaderTitle--white'>Summit #8<br />From Good to Great</h1>
        </div>
        {event && (
          <React.Fragment>
            <div className={`LiveStream__video ${event.showChat ? 'LiveStream__video--with-chat' : ''}`}>
              {event.videoStreamUrl && (
              <div className="LiveStream__video__embed">
                <iframe title="LiveStream" src={event.videoStreamUrl} frameborder="0" allow="autoplay; fullscreen" allowFullScreen style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}></iframe>
              </div>
              )}

              <div className="LiveStream__video__intro">
                <div className="LiveStream__video__intro__title">Welcome to October’s Virtual Summit</div>
                <div className="LiveStream__video__intro__description">
                  {event.introduction}
                </div>
              </div>
            </div>
            {event.showChat && (
              <div className="LiveStream__chat Chat">
                <Chat room={`/event/${event.id}`} />
              </div>
            )}
          </React.Fragment>
        )}
        {!isLoading && !hasErrors && speakers && speakers.length > 0 && (
          <div className="LiveStream__speakers">
            {speakers.map((speaker, index) => (
              <TeamMember classes="Speaker" key={`speaker-${index}`} >
                <img src={speaker.ProfilePicture ? speaker.ProfilePicture.url : process.env.REACT_APP_MISSING_IMAGE_URL} alt={userHelper.name(speaker)} />
                <h3 className="Speaker__name">{userHelper.name(speaker)}</h3>
                <p className="Speaker__role">{speaker.role}</p>
                <p className="Speaker__location">{speaker.location}</p>
                <p className="Speaker__biography">{speaker.biography}</p>
              </TeamMember>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default LiveStream;
