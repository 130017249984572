import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from './reducers';

const loggerMiddleware = createLogger();

let middleware;
if ( process.env.NODE_ENV === "production" ) {
  middleware = applyMiddleware(thunkMiddleware);
} else {
  middleware = applyMiddleware(thunkMiddleware, loggerMiddleware);
}

export default createStore(rootReducer, middleware);
