import React from 'react';
import Arrow from '../assets/images/arrow.png';

const Button = ({ classes, onClick, enabled, text, type }) => {
  return (
    <button className={classes} onClick={onClick} disabled={!enabled} type={type || 'button'}>
      {text} <img src={Arrow} alt="" />
    </button>
  )
}

export default Button;