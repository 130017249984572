import {
  GET_TEAM,
  GET_TEAM_SUCCESS,
  GET_TEAM_ERROR
} from '../actions/teamActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  team: null
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEAM:
      return { ...state, isLoading: true };
    case GET_TEAM_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, team: action.team };
    case GET_TEAM_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default teamReducer;