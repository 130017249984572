import React from 'react';
import { Route } from 'react-router-dom';

import { Redirect } from 'react-router-dom';
import { getAccessToken } from '../helpers/authHeader';

export default class ProtectedRoute extends React.Component {
  render() {
    const Component = this.props.component;

    const isAuthenticated = !!getAccessToken();

    return isAuthenticated ? (
      <Route {...this.props}>
        <Component />
      </Route>
    ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
  }
}
