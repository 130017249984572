import APIService from '../../helpers/apiService';
import dayjs from 'dayjs';
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

export const PRE_EVENT_NETWORKING_STATUS_BEFORE = 'PRE_EVENT_NETWORKING_STATUS_BEFORE';
export const PRE_EVENT_NETWORKING_STATUS_READY = 'PRE_EVENT_NETWORKING_STATUS_READY';
export const NETWORKING_STATUS_BEFORE = 'NETWORKING_STATUS_BEFORE';
export const NETWORKING_STATUS_READY = 'NETWORKING_STATUS_READY';
export const POST_EVENT_NETWORKING_STATUS_BEFORE = 'POST_EVENT_NETWORKING_STATUS_BEFORE';
export const POST_EVENT_NETWORKING_STATUS_READY = 'POST_EVENT_NETWORKING_STATUS_READY';
export const NETWORKING_STATUS_COMPLETE = 'NETWORKING_STATUS_COMPLETE';
export const NETWORKING_STATUS_LOOKING_FOR_MATCH = 'NETWORKING_STATUS_LOOKING_FOR_MATCH';
export const NETWORKING_STATUS_IN_SESSION = 'NETWORKING_STATUS_IN_SESSION';
export const NETWORKING_STATUS_ERROR = 'NETWORKING_STATUS_ERROR';

// Trying to find networking match
export const GET_NETWORKING = 'GET_NETWORKING';
export const getNetworking = () => ({ type: GET_NETWORKING });

// Success retrieving networking match
export const GET_NETWORKING_SUCCESS = 'GET_NETWORKING_SUCCESS';
export const getNetworkingSuccess = item => ({ type: GET_NETWORKING_SUCCESS, item });

// Error retrieving networking match
export const GET_NETWORKING_ERROR = 'GET_NETWORKING_ERROR';
export const getNetworkingError = () => ({ type: GET_NETWORKING_ERROR })

// Reset networking (e.g. when you leave component)
export const RESET_NETWORKING = 'RESET_NETWORKING';
export const resetNetworking = () => ({ type: RESET_NETWORKING });

// Save a new networking status
export const SET_NETWORKING_STATUS = 'SET_NETWORKING_STATUS';
export const setNetworkingStatus = status => ({ type: SET_NETWORKING_STATUS, status });

// Determines what the status should be for networking for a given event
export const DETERMINE_NETWORKING_STATUS = 'DETERMINE_NETWORKING_STATUS';

const getStatusForEvent = (event) => {
  const timeNow = dayjs();

  if (event.hasPreEventNetworking && event.preEventNetworkingStartsAt && timeNow.isBefore(dayjs(event.preEventNetworkingStartsAt)) ) {
    return PRE_EVENT_NETWORKING_STATUS_BEFORE;
  }

  if (event.hasPreEventNetworking && event.preEventNetworkingEndsAt && timeNow.isBefore(dayjs(event.preEventNetworkingEndsAt)) ) {
    return PRE_EVENT_NETWORKING_STATUS_READY;
  }

  if ( event.hasNetworking && event.networkingStartsAt && timeNow.isBefore(dayjs(event.networkingStartsAt)) ) {
    return NETWORKING_STATUS_BEFORE;
  }

  if ( event.hasNetworking && event.networkingEndsAt && timeNow.isBefore(dayjs(event.networkingEndsAt)) ) {
    return NETWORKING_STATUS_READY;
  }

  if ( event.hasPostEventNetworking && event.postEventNetworkingStartsAt && timeNow.isBefore(dayjs(event.postEventNetworkingStartsAt)) ) {
    return POST_EVENT_NETWORKING_STATUS_BEFORE;
  }

  if ( event.hasPostEventNetworking && event.postEventNetworkingEndsAt && timeNow.isBefore(dayjs(event.postEventNetworkingEndsAt)) ) {
    return POST_EVENT_NETWORKING_STATUS_READY;
  }

  return NETWORKING_STATUS_COMPLETE;
}

export const determineNetworkingStatus = event => {
  return async dispatch => {
    dispatch(setNetworkingStatus(getStatusForEvent(event)));
  }
}

// Makes API call to get new match, attempt to destroy the old meeting (if allowed)
export const fetchNetworking = (event, lastMeetingId = undefined) => {
  return async dispatch => {
    const currentStatus = getStatusForEvent(event);
    dispatch(setNetworkingStatus(currentStatus));

    if ([
        PRE_EVENT_NETWORKING_STATUS_READY,
        NETWORKING_STATUS_READY,
        POST_EVENT_NETWORKING_STATUS_READY
      ].includes(currentStatus)) {
      dispatch(getNetworking());

      try {
        // First, make API call to destroy old meeting (it may not allow it if a match occurred)
        if ( lastMeetingId !== undefined ) {
          await APIService.destroy(`networking/${lastMeetingId}`);
        }
        // Now find a new match
        const response = await APIService.get('networking');
        dispatch(getNetworkingSuccess(response.data));
      } catch (error) {
        dispatch(getNetworkingError(error));
      }
    }
  };
};