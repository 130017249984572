const WherebyEmbedField = ({ url, roomType, displayName, className }) => {
  const wherebyProps = {
    participant: [
      'embed',
      'minimal',
      'audio=off',
      'video=off',
      'people=off',
      'displayName=' + displayName,
      'background=off'
    ],
    host: [
      'chat=off',
      'leaveButton=off',
      'settingsButton=off',
      'help=off',
      'moreButton=off',
      'pipButton=off',
      'helpButton=off',
      'locking=off',
      'breakout=off',
      'logo=off',
      'people=on',
      'displayName=' + displayName,
      'background=off'
    ],
    videoCall: [
      'embed',
      'minimal',
      'people=off',
      'displayName=' + displayName,
      'background=off',
      'screenshare=on'
    ]
  };
  return (
    <div className={`WherebyRoom ${className}`}>
      <iframe src={`${url}?${wherebyProps[roomType].join('&')}`} allow="camera; microphone; fullscreen; speaker; display-capture" />
    </div>
  );
}

export default WherebyEmbedField;