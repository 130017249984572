import APIService from '../../helpers/apiService';

export const GET_BREAKOUT_ROOMS = 'GET_BREAKOUT_ROOMS';
export const getBreakoutRooms = () => ({ type: GET_BREAKOUT_ROOMS });

export const GET_BREAKOUT_ROOMS_SUCCESS = 'GET_BREAKOUT_ROOMS_SUCCESS';
export const getBreakoutRoomsSuccess = breakoutRooms => ({ type: GET_BREAKOUT_ROOMS_SUCCESS, breakoutRooms });

export const GET_BREAKOUT_ROOMS_ERROR = 'GET_BREAKOUT_ROOMS_ERROR';
export const getBreakoutRoomsError = error => ({ type: GET_BREAKOUT_ROOMS_ERROR, error })

export const fetchBreakoutRooms = () => {
  return async dispatch => {
    dispatch(getBreakoutRooms());
    try {
      const response = await APIService.get('users/breakout-rooms');
      dispatch(getBreakoutRoomsSuccess(response.data));
    } catch (error) {
      dispatch(getBreakoutRoomsError(error));
    }
  };
};