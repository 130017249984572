import React from 'react';

const TeamMember = ({ classes, children }) => {
  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default TeamMember;
