import React from 'react';
import { Highlight } from '../components';

const HIGHLIGHTS_NUMBER = 3;

const HighlightsList = ({ content }) => {

  return (
    <>
      <h2>Highlights</h2>
      <div className="Highlight">
        {content.slice(0, HIGHLIGHTS_NUMBER).map((contentItem, index) => (
          <Highlight key={`highlight-${index}`} {...contentItem.LinkedModel} />
        ))
        }
      </div>
    </>
  )
}

export default HighlightsList;