import {
  GET_BREAKOUT_ROOMS,
  GET_BREAKOUT_ROOMS_SUCCESS,
  GET_BREAKOUT_ROOMS_ERROR
} from '../actions/breakoutRoomsActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  breakoutRooms: []
};

const breakoutRoomsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BREAKOUT_ROOMS:
      return { ...state, isLoading: true };
    case GET_BREAKOUT_ROOMS_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, breakoutRooms: action.breakoutRooms };
    case GET_BREAKOUT_ROOMS_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default breakoutRoomsReducer;