import APIService from '../../helpers/apiService';

export const GET_SESSIONS = 'GET_SESSIONS';
export const getSessions = () => ({ type: GET_SESSIONS });

export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS';
export const getSessionsSuccess = sessions => ({ type: GET_SESSIONS_SUCCESS, sessions });

export const GET_SESSIONS_ERROR = 'GET_SESSIONS_ERROR';
export const getSessionsError = error => ({ type: GET_SESSIONS_ERROR, error })

export const fetchSessions = () => {
  return async dispatch => {
    dispatch(getSessions());
    try {
      const response = await APIService.get('sessions', { 'filter': JSON.stringify({EventId: process.env.REACT_APP_EVENT_ID}) });
      dispatch(getSessionsSuccess(response.data));
    } catch (error) {
      dispatch(getSessionsError(error));
    }
  };
};