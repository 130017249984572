import APIService from '../../helpers/apiService';

export const GET_BREAKOUT_ROOM = 'GET_BREAKOUT_ROOM';
export const getBreakoutRoom = () => ({ type: GET_BREAKOUT_ROOM });

export const GET_BREAKOUT_ROOM_SUCCESS = 'GET_BREAKOUT_ROOM_SUCCESS';
export const getBreakoutRoomSuccess = breakoutRoom => ({ type: GET_BREAKOUT_ROOM_SUCCESS, breakoutRoom });

export const GET_BREAKOUT_ROOM_ERROR = 'GET_BREAKOUT_ROOM_ERROR';
export const getBreakoutRoomError = error => ({ type: GET_BREAKOUT_ROOM_ERROR, error })

export const fetchBreakoutRoom = (id) => {
  return async dispatch => {
    dispatch(getBreakoutRoom());
    try {
      const response = await APIService.get(`users/breakout-rooms/${id}`);
      dispatch(getBreakoutRoomSuccess(response.data));
    } catch (error) {
      dispatch(getBreakoutRoomError(error));
    }
  };
};