import APIService from '../../helpers/apiService';

export const GET_TEAM = 'GET_TEAM';
export const getTeam = () => ({ type: GET_TEAM });

export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const getTeamSuccess = team => ({ type: GET_TEAM_SUCCESS, team });

export const GET_TEAM_ERROR = 'GET_TEAM_ERROR';
export const getTeamError = error => ({ type: GET_TEAM_ERROR, error })

export const fetchTeam = () => {
  return async dispatch => {
    dispatch(getTeam());
    try {
      const response = await APIService.get('users/team');
      dispatch(getTeamSuccess(response.data));
    } catch (error) {
      dispatch(getTeamError(error));
    }
  };
};