const name = user => {
  return user.firstName + ' ' + user.lastName;
};

const profilePictureName = user => {
  return name(user).normalize("NFD").replace(/[\u00f8]/g, "o").replace(/[\u0300-\u036f]/g, "").replace(/[^a-z]+/gi, "").toLowerCase();
};

export default {
  name,
  profilePictureName
}
