import {
  GET_HOME_CONTENT_ITEMS,
  GET_HOME_CONTENT_ITEMS_SUCCESS,
  GET_HOME_CONTENT_ITEMS_ERROR
} from '../actions/homeContentItemsActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  quicklinks: [],
  highlights: []
};

const homeContentItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_CONTENT_ITEMS:
      return { ...state, isLoading: true };
    case GET_HOME_CONTENT_ITEMS_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, quicklinks: action.quicklinks, highlights: action.highlights };
    case GET_HOME_CONTENT_ITEMS_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default homeContentItemsReducer;