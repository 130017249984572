import APIService from '../../helpers/apiService';

export const GET_HOME_CONTENT_ITEMS = 'GET_HOME_CONTENT_ITEMS';
export const getHomeContentItems = () => ({ type: GET_HOME_CONTENT_ITEMS });

export const GET_HOME_CONTENT_ITEMS_SUCCESS = 'GET_HOME_CONTENT_ITEMS_SUCCESS';
export const getHomeContentItemsSuccess = items => ({
  type: GET_HOME_CONTENT_ITEMS_SUCCESS, 
  quicklinks: items.filter(i => i.section === "main"),
  highlights: items.filter(i => i.section === "highlight")
});

export const GET_HOME_CONTENT_ITEMS_ERROR = 'GET_HOME_CONTENT_ITEMS_ERROR';
export const getHomeContentItemsError = error => ({ type: GET_HOME_CONTENT_ITEMS_ERROR, error })

export const fetchHomeContentItems = () => {
  return async dispatch => {
    dispatch(getHomeContentItems());
    try {
      const response = await APIService.get('home-content-items');
      dispatch(getHomeContentItemsSuccess(response.data));
    } catch (error) {
      dispatch(getHomeContentItemsError(error));
    }
  };
};