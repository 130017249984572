import React from "react";

function ArchiveFilter() {
  const subNavItems = [ 'Video', 'Presentation', 'Module', 'Articles', 'Links', 'Another', 'Another', 'Another', 'Another']

  return (
    <div className="ArchiveFilter">
      <div className="ArchiveFilter__grid">
        <ul>
          {subNavItems.map(subNavItem => (
            <li key={subNavItem + Math.random()}>{subNavItem}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
export default ArchiveFilter;