import {
  GET_ARCHIVE_ITEM,
  GET_ARCHIVE_ITEM_SUCCESS,
  GET_ARCHIVE_ITEM_ERROR
} from '../actions/archiveItemActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  item: null
};

const archiveItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARCHIVE_ITEM:
      return { ...state, isLoading: true };
    case GET_ARCHIVE_ITEM_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, item: action.item };
    case GET_ARCHIVE_ITEM_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default archiveItemReducer;