import APIService from '../../helpers/apiService';

export const GET_SPEAKERS = 'GET_SPEAKERS';
export const getSpeakers = () => ({ type: GET_SPEAKERS });

export const GET_SPEAKERS_SUCCESS = 'GET_SPEAKERS_SUCCESS';
export const getSpeakersSuccess = speakers => ({ type: GET_SPEAKERS_SUCCESS, speakers });

export const GET_SPEAKERS_ERROR = 'GET_SPEAKERS_ERROR';
export const getSpeakersError = error => ({ type: GET_SPEAKERS_ERROR, error })

export const fetchSpeakers = () => {
  return async dispatch => {
    dispatch(getSpeakers());
    try {
      const response = await APIService.get(`events/${process.env.REACT_APP_EVENT_ID}/speakers`);
      dispatch(getSpeakersSuccess(response.data));
    } catch (error) {
      dispatch(getSpeakersError(error));
    }
  };
};