import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchNetworking, 
  resetNetworking,
  determineNetworkingStatus
} from '../redux/actions/networkingActions';
import { AppContext } from '../App';
import { NetworkingSessionMeeting } from '../components';

const Networking = () => {
  const dispatch = useDispatch();
  const { event } = useContext(AppContext);
    
  const { networkingMeeting, status } = useSelector(
    ({ networking }) => ({
      status: networking.status,
      networkingMeeting: networking.item
    })
  );

  useEffect(() => {
    if (status === "PRE_EVENT_NETWORKING_STATUS_BEFORE" || status === "NETWORKING_STATUS_BEFORE" || status === "POST_EVENT_NETWORKING_STATUS_BEFORE") {
      const interval = setInterval(() => {
        dispatch(determineNetworkingStatus(event));
      }, 5000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [status]);

  useEffect(() => {
    if (!event) {
      return;
    }

    dispatch(determineNetworkingStatus(event));
    return () => {
      dispatch(resetNetworking());
    }
  }, [event, dispatch]);

  const onEnd = () => {
    dispatch(fetchNetworking(event, networkingMeeting.id));
  }

  const onReady = () => {
    dispatch(fetchNetworking(event));
  }

  return (
    <div className="Networking">
      <div className="Networking__grid">
        <div className="Networking__grid__container">
          <h1 className="HeaderTitle HeaderTitle--left HeaderTitle--white">Get networking</h1>
          {event && (event.hasNetworking || event.hasPreEventNetworking || event.hasPostEventNetworking ) ? (
            {
              [undefined]: (
                <div className="Networking__grid__container__intro">
                  <p>Loading networking session information</p>
                </div>
              ),
              PRE_EVENT_NETWORKING_STATUS_BEFORE: (
                <div className="Networking__grid__container__intro">
                  <p>The Networking area is closed at the moment. Please go to the Live Stage to take part in the summit. </p>
                </div>
              ),
              PRE_EVENT_NETWORKING_STATUS_READY: (
                <>
                  <div className="Networking__grid__container__intro">
                    <p>Welcome to the networking session. Once you are paired with a participant this text will be replaced with a {event.networkingCallDurationInMinutes} minute video call.</p>
                    <p>You will continue to be paired with new participants after each call until the networking session ends. Please click the button below to start.</p>
                    <button className="Button" onClick={onReady}>I'm Ready</button>
                  </div>
                  <FormCopy />
                </>
              ),
              NETWORKING_STATUS_BEFORE: (
                <div className="Networking__grid__container__intro">
                  <p>The Networking area is closed at the moment. Please go to the Live Stage to take part in the summit.</p>
                </div>
              ),
              NETWORKING_STATUS_READY: (
                <>
                  <div className="Networking__grid__container__intro">
                    <p>Welcome to the networking session. Once you are paired with a participant this text will be replaced with a {event.networkingCallDurationInMinutes} minute video call.</p>
                    <p>You will continue to be paired with new participants after each call until the networking session ends. Please click the button below to start.</p>
                    <button className="Button" onClick={onReady}>I'm Ready</button>
                  </div>
                  <FormCopy />
                </>
              ),
              POST_EVENT_NETWORKING_STATUS_BEFORE: (
                <div className="Networking__grid__container__intro">
                  <p>The mid-summit Networking session has now closed. Please go to the Live Stage or another part of the site to take part in the summit. We will be opening up Networking again later on.</p>
                </div>
              ),
              POST_EVENT_NETWORKING_STATUS_READY: (
                <>
                  <div className="Networking__grid__container__intro">
                    <p>Welcome to the networking session. Once you are paired with a participant this text will be replaced with a {event.networkingCallDurationInMinutes} minute video call.</p>
                    <p>You will continue to be paired with new participants after each call until the networking session ends. Please click the button below to start.</p>
                    <button className="Button" onClick={onReady}>I'm Ready</button>
                  </div>
                  <FormCopy />
                </>
              ),
              NETWORKING_STATUS_LOOKING_FOR_MATCH: (
                <>
                  <div className="Networking__grid__container__intro">
                    <p>We're now matching you with another attendee for a {event.networkingCallDurationInMinutes} minute networking session. Please wait...</p>
                  </div>
                  <FormCopy />
                </>
              ),
              NETWORKING_STATUS_IN_SESSION: (
                <>
                  <NetworkingSessionMeeting onEnd={onEnd} meeting={networkingMeeting} />
                  <FormCopy />
                </>
              ),
              NETWORKING_STATUS_COMPLETE: (
                <div className="Networking__grid__container__intro">
                  <p>Networking has now finished for today. Please return to the Live Stage to take part in the summit.</p>
                </div>
              ),
              NETWORKING_STATUS_ERROR: (
                <div className="Networking__grid__container__intro">
                  <p>There was an error finding you a match; please try again.</p>
                  <button className="Button" onClick={onReady}>Try Again</button>
                </div>
              )
            }[status]
          ) : (
            <div className="Networking__grid__container__intro">
              <p>Networking has now finished for today. Please return to the Live Stage to take part in the summit.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const FormCopy = () => {
  return (
    <div className="Networking__grid__container__intro">
      <p>
        Use this link to open Microsoft Forms and answer the relevant questions: <a style={{ textDecoration: 'underline', color: 'white' }} href="https://forms.office.com/e/nmujtmw6gE" target="_blank" rel="noreferrer">https://forms.office.com/e/nmujtmw6gE</a>  
      </p>
    </div>
  )
}

export default Networking;
