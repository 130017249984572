import { authHeader, clearAccessToken } from './authHeader';
import { stringify } from 'querystring';

const apiHost = process.env.REACT_APP_API_URL;

const request = async (method, endPoint, params) => {
  const queryString = method === 'GET' && params ? `?${stringify(params)}` : "";
  const body = (method === 'POST' || method === 'PATCH') && params ? JSON.stringify(params) : undefined;

  const response = await fetch(`${apiHost}/${endPoint}${queryString}`, {
    method: method,
    headers: authHeader(),
    body: body
  })

  const returnValue = {
    status: response.status
  }

  if (response.status === 403 && window.location.pathname !== '/login') {
    clearAccessToken();
    window.location = '/login';
    return returnValue;
  }
  
  if (response.ok) {
    try {
      returnValue.data = await response.json()
    } catch (error) {
      // Nothing here
    }
  }

  return returnValue;
}

const post = async (endPoint, body) => {
  return await request("POST", endPoint, body);
}

const patch = async (endPoint, body) => {
  return await request("PATCH", endPoint, body);
}

const get = async (endPoint, queryParams) => {
  return await request("GET", endPoint, queryParams);
}

const destroy = async (endPoint, queryParams) => {
  return await request("DELETE", endPoint, queryParams);
}

export default {
  get, post, patch, destroy, apiHost
}
