import React from 'react';
import { Link } from 'react-router-dom';


const Highlight = ({ id, title, introduction, PreviewImage }) => {
  return (
    <Link className='Highlight__card' to={`/archive/${id}`}>
      <img src={PreviewImage ? PreviewImage.url : process.env.REACT_APP_MISSING_IMAGE_URL} alt={title}/>  
      <h3>{title}</h3>
      <p>{introduction}</p>
    </Link>
  )
}

export default Highlight;