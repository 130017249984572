import {
  GET_ARCHIVE_ITEMS,
  GET_ARCHIVE_ITEMS_SUCCESS,
  GET_ARCHIVE_ITEMS_ERROR
} from '../actions/archiveItemsActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  items: []
};

const archiveItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARCHIVE_ITEMS:
      return { ...state, isLoading: true };
    case GET_ARCHIVE_ITEMS_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, items: action.items };
    case GET_ARCHIVE_ITEMS_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default archiveItemsReducer;
