import React, { useEffect, useState, useCallback, useContext, useRef, useMemo } from 'react';
import Linkify from 'linkify-react';

import { AppContext } from '../App';
import userHelper from '../helpers/userHelper';
import APIService from '../helpers/apiService';
import dateHelper from '../helpers/dateHelper';
import { ChatForm } from './';
import { getAccessToken } from '../helpers/authHeader';

// percentage of messages container where if user scrolled up by less than this amout we scroll to bottom on new messages.
// this is to cover the case where the user scrolled a tiny bit up but they still want to see new messages.
const TRIGGER_SCROLL_LIMIT_PERCENT = 0.2;

const Chat = ({ room, BreakoutRoomId, TeamId }) => {
  const token = getAccessToken();
  const { socket } = useContext(AppContext);
  const messagesContainerRef = useRef(null);
  const [messagesList, setMessagesList] = useState([]);
  const scrollToBottom = () => messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;

  const pinnedMessage = useMemo(() => (
    messagesList.filter(message => message.pinned).sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)[0]
  ), [messagesList])

  const messageHandler = useCallback((data) => {
    setMessagesList([...messagesList, data]);
    scrollToBottom();
  }, [messagesList]);

  useEffect(() => {
    if ( socket.connected ) {
      socket.emit('join', room);
    } else {
      socket.on('connect', () => {
        socket.emit('join', room);
      });
    }
    socket.on('message', messageHandler);
    return () => {
      socket.emit('leave', room);
      socket.off('message', messageHandler);
    }
  }, [messageHandler, socket]);

  useEffect(() => {
    let filter;
    if ( BreakoutRoomId ) {
      filter = { BreakoutRoomId };
    } else if ( TeamId ) {
      filter = { TeamId };
    } else {
      filter = { EventId: process.env.REACT_APP_EVENT_ID };
    }
    APIService.get('messages', filter).then(({status, data}) => {
      if (status === 200 && messagesContainerRef.current !== null) {
        const scrollTriggerPX = messagesContainerRef.current.clientHeight * TRIGGER_SCROLL_LIMIT_PERCENT;
        const shouldScrollToBottom = messagesContainerRef.current.scrollTop + messagesContainerRef.current.clientHeight >= messagesContainerRef.current.scrollHeight - scrollTriggerPX;
        setMessagesList([...messagesList, ...data]);
  
        if (shouldScrollToBottom && data.length > 0) {
          scrollToBottom();
        }
      }
    });
  }, [token, BreakoutRoomId, TeamId]);

  return (
    <>
      <div className="Chat__title">{(BreakoutRoomId || TeamId) ? "Room Chat" : "Live Stage"}</div>
      {pinnedMessage && (
        <div className="Message Message--pinned Message--pinned--top">
          <img src={pinnedMessage.User.ProfilePicture ? pinnedMessage.User.ProfilePicture.url : process.env.REACT_APP_MISSING_IMAGE_URL} alt={userHelper.name(pinnedMessage.User)} />
          <div className="Message__text">
            <div>{userHelper.name(pinnedMessage.User)}</div>
            <time>{dateHelper.format(pinnedMessage.createdAt, 'Do MMM YYYY @ h:mma')}</time>
            <Linkify options={{ className: "", attributes: { rel: "noopener noreferrer" }}}>{pinnedMessage.text}</Linkify>
          </div>
        </div>
      )}
      <div className="Chat__messages" ref={messagesContainerRef}>
        {messagesList.map((message, index) => (
          <div key={`message-${index}`} className={`Message ${message.id === pinnedMessage?.id ? "Message--pinned" : ""}`}>
            <img src={message.User.ProfilePicture ? message.User.ProfilePicture.url : process.env.REACT_APP_MISSING_IMAGE_URL} alt={userHelper.name(message.User)} />
            <div className="Message__text">
              <div>{userHelper.name(message.User)}</div>
              <time>{dateHelper.format(message.createdAt, 'Do MMM YYYY @ h:mma')}</time>
              <Linkify options={{ target:"_blank", className: "", attributes: { rel: "noopener noreferrer" }}}>{message.text}</Linkify>
            </div>
          </div>
        ))}
      </div>
      <ChatForm BreakoutRoomId={BreakoutRoomId} TeamId={TeamId} />
    </>
  )
}

export default Chat;
