export const authHeader = () => {
  const token = getAccessToken();
  if (token) {
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  } else {
    return {
      'Content-Type': 'application/json'
    }
  }
}

export const saveAccessToken = (token) => {
  localStorage.setItem("frontend-token", token);
}

export const getAccessToken = () => {
  return localStorage.getItem("frontend-token");
}

export const clearAccessToken = () => {
  localStorage.removeItem("frontend-token");
}