import { useEffect, useState, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';

import { fetchEvent } from './redux/actions/eventActions';
import { getAccessToken } from './helpers/authHeader';
import APIService from './helpers/apiService';

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import {
  Home,
  Login,
  LiveStream,
  TeamSpace,
  ProtectedRoute,
  Networking
} from './screens'

import { NavBar } from './components';

export const AppContext = createContext({
  socket: null,
  event: null
});

const App = () => {
  const dispatch = useDispatch();
  const token = getAccessToken();
  const [socket, setSocket] = useState(null);

  const { event } = useSelector(
    ({ event }) => ({
      event: event.item
    })
  );

  useEffect(() => {
    const socket = io(APIService.apiHost.replace('/api', ''), {
      auth: { token }
    });
    setSocket(socket);
  }, [token]);

  useEffect(() => {
    dispatch(fetchEvent(process.env.REACT_APP_EVENT_ID));
  }, [token, dispatch]);

  return (
    <AppContext.Provider value={{ socket, event }}>
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route path='/login/:token?' component={Login} />
          <ProtectedRoute exact path='/' component={Home} />
          <ProtectedRoute path='/live' component={LiveStream} />
          <ProtectedRoute path='/team-space' component={TeamSpace} />
          <ProtectedRoute path='/networking' component={Networking} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App;
