import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';

import APIService from '../helpers/apiService';

import { Button } from '../components';
  
const ChatForm = ({ BreakoutRoomId, TeamId }) => {
  const [text, setText] = useState("");
  const [pinned, setPinned] = useState(false);
  const [canPinChatMessage, setCanPinChatMessage] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const canSubmit = useMemo(() => (
    !sendingMessage && text.trim().length > 0 && text.length < 256
  ), [text, sendingMessage]);

  useEffect(() => {
    const getPinStatus = async () => {
      const { data: { canPinChatMessage } } = await APIService.get('viewer');
      setCanPinChatMessage(canPinChatMessage);
    }

    getPinStatus();
  }, []);

  const onSubmit = async (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    if (!canSubmit) {
      return;
    }
    setSendingMessage(true);

    const data = {
      text: text.replace(/\n\n+/g, `\n`),
      pinned: pinned,
      EventId: process.env.REACT_APP_EVENT_ID
    };
    if ( BreakoutRoomId ) {
      data.BreakoutRoomId = BreakoutRoomId
    } else if ( TeamId ) {
      data.TeamId = TeamId
    }
    const { status } = await APIService.post('messages', data);

    if (status === 201) {
      setText("");
      setPinned(false);
      setSendingMessage(false);
    }
  }

  const onKeyDownTextarea = e => {
    if ((e.which === 13 || e.key === "Enter") && !e.shiftKey){
      e.preventDefault();
      onSubmit();
    }
  }

  const onChangeTextarea = e => {
    setText(e.target.value.substring(0, 255));
  }

  const onChangeCheckbox = e => {
    setPinned(!pinned);
  }

  return (
    <div className="Chat__form">
      <form onSubmit={onSubmit}>
        <label>
          <textarea value={text} onKeyDown={onKeyDownTextarea} onChange={onChangeTextarea} type='text' name='name' placeholder='Type your comments or questions here...' />
        </label>
        {canPinChatMessage && (
          <label htmlFor='pin-message' className='pin-message-option'>
            Pin message
            <input type='checkbox' name='pin-message' id='pin-message' checked={pinned} onChange={onChangeCheckbox}/>
          </label>
        )}
        <span className={classNames({ "Chat__form__counter": true, "Chat__form__counter--exceeded": text.length >= 255 })}>{255 - (text.length)} characters left</span>
        <Button text='Send' classes='Button Button--blue' enabled={canSubmit} type="submit" />
      </form>
    </div>
  )
}

export default ChatForm;