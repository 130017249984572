import React, { useEffect, useState, useContext } from 'react';
import { decode } from 'jsonwebtoken';

import { AppContext } from '../App';
import { getAccessToken } from '../helpers/authHeader';
import { WherebyEmbed, NetworkingCountdownTimer } from '../components';

const NetworkingSessionMeeting = ({ meeting, onEnd }) => {
  const token = getAccessToken();
  const [displayName, setDisplayName] = useState('Guest');
  const [userCount, setUserCount] = useState(1);
  const { event, socket } = useContext(AppContext);

  useEffect(() => {
    const { name } = decode(token);
    setDisplayName(name);
  }, [token]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    
    const room = `/networking/${meeting.id}`;
    const userCountHandler = updatedUserCount => {
      const newCount = Number(updatedUserCount);
      console.log(`User count going from ${userCount} ${newCount}`);
      setUserCount(newCount);
      if ( newCount < userCount ) {
        onEnd();
      }
    };

    if ( socket.connected ) {
      socket.emit('join', room);
    } else {
      socket.on('connect', () => {
        socket.emit('join', room);
      });
    }
    socket.on('userCount', userCountHandler);
    return () => {
      socket.emit('leave', room);
      socket.off('userCount', userCountHandler);
    }
  }, [socket]);

  return (
    <div className="Networking__session">
      <NetworkingCountdownTimer key={`countdown-${meeting.id}`} duration={60 * event.networkingCallDurationInMinutes} onEnd={onEnd} started={userCount === 2} />
      <WherebyEmbed key={`whereby-${meeting.id}`} roomType="videoCall" url={meeting.CreatingUser.roomUrl} displayName={displayName} className="Networking__session__call" />
    </div>
  )
}

export default NetworkingSessionMeeting;