import {
  SET_NETWORKING_STATUS,
  GET_NETWORKING,
  GET_NETWORKING_SUCCESS,
  GET_NETWORKING_ERROR,
  RESET_NETWORKING,
  NETWORKING_STATUS_LOOKING_FOR_MATCH,
  NETWORKING_STATUS_IN_SESSION,
  NETWORKING_STATUS_ERROR
} from '../actions/networkingActions'

const initialState = {
  item: null,
  status: undefined
};

const networkingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NETWORKING_STATUS:
      return { ...initialState, status: action.status };
    case GET_NETWORKING:
      return { ...initialState, status: NETWORKING_STATUS_LOOKING_FOR_MATCH };
    case GET_NETWORKING_SUCCESS:
      return { ...state, status: NETWORKING_STATUS_IN_SESSION, item: action.item };
    case GET_NETWORKING_ERROR:
      return { ...initialState, status: NETWORKING_STATUS_ERROR };
    case RESET_NETWORKING: 
      return { ...initialState };
    default:
      return state;
  }
}

export default networkingReducer;