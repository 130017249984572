import APIService from '../../helpers/apiService';

export const GET_EVENT = 'GET_EVENT';
export const getEvent = () => ({ type: GET_EVENT });

export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const getEventSuccess = item => ({ type: GET_EVENT_SUCCESS, item });

export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';
export const getEventError = error => ({ type: GET_EVENT_ERROR, error })

export const fetchEvent = (id) => {
  return async dispatch => {
    dispatch(getEvent());
    try {
      const response = await APIService.get(`events/${id}`);
      if ( response.status === 404 ) { 
        dispatch(getEventError('Not found'));
      } else {
        dispatch(getEventSuccess(response.data));
      }
    } catch (error) {
      dispatch(getEventError(error));
    }
  };
};