import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { saveAccessToken } from '../helpers/authHeader';
import APIService from '../helpers/apiService';
import { LoginForm } from '../components';

const Login = props => {
  const { token } = props.match.params
  const history = useHistory();

  useEffect(() => {
    async function verifyToken(token) {
      const { status } = await APIService.post('users/verify', { token });

      if (status === 200) {
        saveAccessToken(token);
        history.push('/')
      } else if (status === 403) {
        history.push('/login')
      }
    }

    if (token) {
      verifyToken(token);
    }
  }, [token, history])

  return (
    <React.Fragment>
      <div className='Login'>
        <div className='Login__grid'>
          <div className='Login__grid__container'>
            <h1 className='HeaderTitle HeaderTitle--left HeaderTitle--white'>
              <span>Welcome to</span><br />
              <span>Energy Systems</span>
            </h1>
            {token ? <p className='intro-para shadow'>Logging you in...</p> : <LoginForm />}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login;
