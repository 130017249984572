import React from 'react';
import { NavLink } from 'react-router-dom';

import DnvglLogo from '../assets/images/dnv-logo-long.svg';
import FFTS from '../assets/images/FFTS_200.png';

const NavBar = () => {
  return (
    <div className="NavBar">
      <div className="NavBar__grid">
        <div className="NavBar__grid__container">
        <img className="dnv-logo" src={DnvglLogo} alt="DNV-GL logo" />
          <ul>
            <li>
              <NavLink exact to='/' target="_blank">Home</NavLink>
            </li>
            <li>
              <NavLink to='/live' target="_blank">Live Stage</NavLink>
            </li>
            <li>
              <NavLink to='/networking' target="_blank">Networking</NavLink>
            </li>
          </ul>
          <img src={FFTS} className="ffts-logo" alt="FFTS logo" />
         </div>
      </div>
    </div>
  )
}

export default NavBar;