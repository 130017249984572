import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../App';
import dateHelper from '../helpers/dateHelper';
import { SessionCard } from '../components';

import downArrow from '../assets/images/down-arrow-blue.png';
import upArrow from '../assets/images/up-arrow.png';

const SCHEDULE_CONTAINER_HEIGHT = 530;

// Adding a comment to force a build.
const SessionsList = ({ sessions }) => {
  const { event } = useContext(AppContext);
  const [schedulePage, setSchedulePage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const scheduleContainer = useRef(null); 
  const scrollDownArrow = useRef(null);
  const scrollUpArrow = useRef(null);

  useEffect(() => {
    const scheduleHeight = scheduleContainer.current.getBoundingClientRect().height;
    const pageCount = Math.ceil(scheduleHeight / SCHEDULE_CONTAINER_HEIGHT);
    setNumPages(pageCount);
  }, [sessions]);

  const schedulePosition = ((schedulePage - 1) * -SCHEDULE_CONTAINER_HEIGHT) + 'px';

  return (
    <div className="ComingUp">
      <h2>Agenda</h2>
      {event && (
        <div className="ComingUp__intro">
          <h3>{dateHelper.format(event.displayDate, 'D MMMM YYYY')}</h3>
          {event.scheduleSummary.length > 2 && (
            <p>{event.scheduleSummary}</p>
          )}
        </div>
      )}
      <button ref={scrollUpArrow} disabled={schedulePage === 1}>
        <img src={upArrow} alt="" />
      </button>
      <div className="event-container">
        <div ref={scheduleContainer} className="event-container__sessions" style={{ top: schedulePosition }}>
          {sessions.map(digitalSession => (
            <SessionCard key={`digital-event-${digitalSession.id}`} type={digitalSession.type}>
              <div className="ComingUp__card">
                <div className="ComingUp__card__image">
                  <img src={digitalSession.MediaItem ? digitalSession.MediaItem.url : process.env.REACT_APP_MISSING_IMAGE_URL} alt={digitalSession.title} />
                </div>
                <div className="ComingUp__card__content">
                  <h4 dangerouslySetInnerHTML={{ __html: digitalSession.title }} />
                  <p className='body'>{digitalSession.description}</p>
                  {digitalSession.linkHref ? (
                    <Link className='time' to={digitalSession.linkHref}>{digitalSession.location} {dateHelper.format(digitalSession.time, 'h:mma')}</Link>
                  ) : (
                    <p className='time'>{digitalSession.location} {dateHelper.format(digitalSession.time, 'h:mma')}</p>
                  )}
                </div>
              </div>
            </SessionCard>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SessionsList;
