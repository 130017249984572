import {
  GET_SPEAKERS,
  GET_SPEAKERS_SUCCESS,
  GET_SPEAKERS_ERROR
} from '../actions/speakersActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  speakers: []
};

const speakersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPEAKERS:
      return { ...state, isLoading: true };
    case GET_SPEAKERS_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, speakers: action.speakers };
    case GET_SPEAKERS_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default speakersReducer;