import APIService from '../../helpers/apiService';

export const GET_ARCHIVE_ITEMS = 'GET_ARCHIVE_ITEMS';
export const getArchiveItems = () => ({ type: GET_ARCHIVE_ITEMS });

export const GET_ARCHIVE_ITEMS_SUCCESS = 'GET_ARCHIVE_ITEMS_SUCCESS';
export const getArchiveItemsSuccess = items => ({ type: GET_ARCHIVE_ITEMS_SUCCESS, items });

export const GET_ARCHIVE_ITEMS_ERROR = 'GET_ARCHIVE_ITEMS_ERROR';
export const getArchiveItemsError = error => ({ type: GET_ARCHIVE_ITEMS_ERROR, error })

export const fetchArchiveItems = () => {
  return async dispatch => {
    dispatch(getArchiveItems());
    try {
      const response = await APIService.get('archive-items');
      dispatch(getArchiveItemsSuccess(response.data));
    } catch (error) {
      dispatch(getArchiveItemsError(error));
    }
  };
};