import {
  GET_SESSIONS,
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS_ERROR
} from '../actions/sessionsActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  sessions: []
};

const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSIONS:
      return { ...state, isLoading: true };
    case GET_SESSIONS_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, sessions: action.sessions };
    case GET_SESSIONS_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default sessionsReducer;