import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchHomeContentItems } from '../redux/actions/homeContentItemsActions';
import { fetchSessions } from '../redux/actions/sessionsActions';
import { QuickLinksList, HighlightsList, SessionsList } from '../components';
import Headline from '../assets/images/headline.png';
import SubHeadline from '../assets/images/sub-headline.png';

const Home = () => {
  const dispatch = useDispatch();

  const { quicklinks, highlights, sessions, homeContentItemsIsLoading, homeContentItemsHasErrors, sessionsIsLoading, sessionsHasErrors } = useSelector(
    ({ homeContentItems, sessions }) => ({
      quicklinks: homeContentItems.quicklinks,
      highlights: homeContentItems.highlights,
      sessions: sessions.sessions,
      homeContentItemsIsLoading: homeContentItems.isLoading,
      homeContentItemsHasErrors: homeContentItems.hasErrors,
      sessionsIsLoading: sessions.isLoading,
      sessionsHasErrors: sessions.hasErrors
    })
  );

  useEffect(() => {
    dispatch(fetchHomeContentItems());
    dispatch(fetchSessions());
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="Home">
        <div className="Home__grid">
          <div className="Home__grid__container">
            {/* <h1 className='HeaderTitle HeaderTitle--white'>Forward Faster <br />Together Summit</h1> */}
            <div className="Home__grid__container__imageHeader">
              <img src={Headline} alt="Forward Faster Together Summit" className="Home__grid__container__imageHeader-headline" />
              <img src={SubHeadline} alt="Effective Sales and Delivery" className="Home__grid__container__imageHeader-subheadline" />
            </div>
          </div>
        </div>

        <div className="Home__grid">
          <div className="Home__grid__left">
            <p className="Home__intro">
              Welcome to the Energy Systems Forward Faster Together Summit for October. You can see the agenda for today&#x2019;s summit on the right-hand side. The main presentations will be broadcast on the <Link target="_blank" to="/live" title="Live Stage">Live Stage</Link> – so be sure to go to the Live Stage before the scheduled start time. We will also run a networking session during the summit: we will direct you to our <Link target="_blank" to="/networking" title="Networking">Networking</Link> page when this begins.
            </p>
            {homeContentItemsIsLoading || homeContentItemsHasErrors ? null : (
              <React.Fragment>
                <div className="Home__grid__quick-links">
                  <QuickLinksList content={quicklinks} />
                </div>
                {highlights.length > 0 && (
                  <div className="Home__grid__highlights">
                    <HighlightsList content={highlights} />
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="Home__grid__right">
            {sessionsIsLoading || sessionsHasErrors ? null : (
              <SessionsList sessions={sessions} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home;
