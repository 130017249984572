import APIService from '../../helpers/apiService';

export const GET_ARCHIVE_ITEM = 'GET_ARCHIVE_ITEM';
export const getArchiveItem = () => ({ type: GET_ARCHIVE_ITEM });

export const GET_ARCHIVE_ITEM_SUCCESS = 'GET_ARCHIVE_ITEM_SUCCESS';
export const getArchiveItemSuccess = item => ({ type: GET_ARCHIVE_ITEM_SUCCESS, item });

export const GET_ARCHIVE_ITEM_ERROR = 'GET_ARCHIVE_ITEM_ERROR';
export const getArchiveItemError = error => ({ type: GET_ARCHIVE_ITEM_ERROR, error })

export const fetchArchiveItem = (id) => {
  return async dispatch => {
    dispatch(getArchiveItem());
    try {
      const response = await APIService.get(`archive-items/${id}`);
      if ( response.status === 404 ) { 
        dispatch(getArchiveItemError('Not found'));
      } else {
        dispatch(getArchiveItemSuccess(response.data));
      }
    } catch (error) {
      dispatch(getArchiveItemError(error));
    }
  };
};