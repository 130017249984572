import React, { useState } from "react";

function QuickLink({ ThemeColour, introduction, title, PreviewImage, id, pageUrl }) {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <a href={pageUrl || `/archive/${id}`} target="_blank" rel="noreferrer">
      <div className="QuickLink QuickLink" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} style={{ backgroundImage: `url('${PreviewImage ? PreviewImage.url : process.env.REACT_APP_MISSING_IMAGE_URL}')` }} >
        {isHovering ? (
          <div className="QuickLink__back-content" style={{ backgroundColor: ThemeColour.colour }}>
            <div>
              <p>{introduction}</p>
            </div>
          </div>
        ) : (
            <div style={{ backgroundColor: ThemeColour.colour }} className="QuickLink__front-content">
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          )}
      </div>
    </a>
  )
}
export default QuickLink;