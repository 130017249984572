import {
  GET_BREAKOUT_ROOM,
  GET_BREAKOUT_ROOM_SUCCESS,
  GET_BREAKOUT_ROOM_ERROR
} from '../actions/breakoutRoomActions'

const initialState = {
  isLoading: true,
  hasErrors: false,
  breakoutRoom: null
};

const breakoutRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BREAKOUT_ROOM:
      return { ...state, isLoading: true };
    case GET_BREAKOUT_ROOM_SUCCESS:
      return { ...state, isLoading: false, hasErrors: false, breakoutRoom: action.breakoutRoom }
    case GET_BREAKOUT_ROOM_ERROR:
      return { ...state, isLoading: false, hasErrors: true };
    default:
      return state;
  }
}

export default breakoutRoomReducer;