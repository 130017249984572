import React from 'react';
import { QuickLink } from '../components';

const MAIN_LINKS_NUMBER = 4;

const QuickLinksList = ({ content }) => {
  return content.slice(0, MAIN_LINKS_NUMBER).map((contentItem, index) => (
    <QuickLink key={index} {...contentItem.LinkedModel} />
  ))
}


export default QuickLinksList;